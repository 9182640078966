import { getCookie } from "../plugins/cookie";
import { matomoSiteId, matomoSiteIdPers, matomoSiteIdStb, matomoSiteIdBr, matomoSiteIdEbib } from "../../app.config";

// Elemente der Auflistung ist analog zu beck-online, Datei Beck.Definitions.Site.cs
export enum SiteSetting {
  ShowMyBOCheckBox = 1022,
  ShowOptionForSuggestions = 1027,
  ShowOptionForVerlage = 1058,
  TopParagraphMaxCount = 1060,
  ShowOptionForRechtsgebiet = 1067,
  ShowPortalUmleitungPopup = 1082
}

export class PortalInfo {
  css: string;
  portal: string | undefined;
  portalName: string;
  siteId: string;
  matomoSiteId: string;

  constructor(css: string, portal: string | undefined, portalName: string, siteId: string, matomoId: string) {
    this.css = css;
    this.portal = portal;
    this.portalName = portalName;
    this.siteId = siteId;
    this.matomoSiteId = matomoId;
  }

  private persSettings: Array<number> = [
    SiteSetting.ShowMyBOCheckBox,
    SiteSetting.ShowOptionForSuggestions,
    SiteSetting.ShowPortalUmleitungPopup
  ];
  private stbSettings: Array<number> = [
    SiteSetting.ShowMyBOCheckBox,
    SiteSetting.ShowOptionForSuggestions,
    SiteSetting.TopParagraphMaxCount,
    SiteSetting.ShowPortalUmleitungPopup
  ];
  private ebibSettings: Array<number> = [SiteSetting.ShowOptionForSuggestions];
  private brSettings: Array<number> = [SiteSetting.ShowMyBOCheckBox, SiteSetting.ShowOptionForSuggestions];
  private boSettings: Array<number> = [
    SiteSetting.ShowMyBOCheckBox,
    SiteSetting.ShowOptionForSuggestions,
    SiteSetting.ShowOptionForVerlage,
    SiteSetting.TopParagraphMaxCount,
    SiteSetting.ShowOptionForRechtsgebiet,
    SiteSetting.ShowPortalUmleitungPopup
  ];

  hasSetting(settingId: number) {
    switch (this.portal) {
      case "pers":
        return this.persSettings.indexOf(settingId) > -1;
      case "stb":
        return this.stbSettings.indexOf(settingId) > -1;
      case "ebib":
        return this.ebibSettings.indexOf(settingId) > -1;
      case "br":
        return this.brSettings.indexOf(settingId) > -1;
    }

    return this.boSettings.indexOf(settingId) > -1;
  }
}

export const usePortals = (): PortalInfo => {
  interface SiteMapper {
    origin: string;
    site: string;
  }
  const portals: Array<SiteMapper> = [
    { origin: "beck-online", site: "" },
    { origin: "personal", site: "pers" },
    { origin: "steuern", site: "stb" },
    { origin: "ebibliothek", site: "ebib" },
    { origin: "bayernrecht", site: "br" },
    { origin: "beckportal.bybn.de", site: "br" },
    { origin: "beckportal-prd.bybn.de", site: "br" }
  ];

  let portalCss: string = "";

  let displayedSite: string = getCookie("site");
  if (displayedSite.length === 0) {
    // kein Cookie, Site aus Url bestimmen
    let origin: string = window.location.origin;
    if (origin === "localhost") origin = "beck-online"; // Fallback für lokale Entwicklung
    const portal: SiteMapper | undefined = portals.find((el) => origin.includes(el.origin));
    if (portal !== undefined) {
      displayedSite = portal.site;
    }
  }

  let portalName : string = "beck-online";
  let siteId : string = "";
  let matomoId : string = matomoSiteId;
  let scheme: string = "light";
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      scheme = "dark";
  }

  switch (displayedSite) {
    case "pers":
      portalCss = "beck-personal-portal beck-" + scheme;
      portalName = "Personal-Lexikon";
      siteId = "d1b61fcb-439b-446f-a2d4-b2c19569231d";
      matomoId = matomoSiteIdPers;
      break;
    case "stb":
      portalCss = "beck-steuern-portal beck-" + scheme;
      siteId = "78ba2de0-ee48-4463-97b7-c530e2882538";
      matomoId = matomoSiteIdStb;
      break;
    case "ebib":
      portalCss = "beck-ebib-portal beck-" + scheme;
      siteId = "d9296e0c-274d-475b-8509-7c108b7b018e";
      matomoId = matomoSiteIdEbib;
      break;
    case "br":
      portalCss = "beck-br-portal beck-" + scheme;
      portalName = "BAYERN.RECHT";
      siteId = "3780df36-0798-4258-9c1e-9ed40231c4c6";
      matomoId = matomoSiteIdBr;
      break;
    default:
      portalCss = "beck-" + scheme;
      siteId = "7cec2236-032a-4d2f-a203-c9aeac7f6d32";
      matomoId = matomoSiteId;
      break;
  }

  return new PortalInfo(portalCss, displayedSite, portalName, siteId, matomoId);
};
