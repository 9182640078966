export const uiElements = import.meta.env.VITE_UI_ELEMENTS + '/assets/beck-online-ui-elements.js';
export const store = import.meta.env.VITE_STORE + '/assets/storeEntry.js';
export const baseUrl = import.meta.env.VITE_BASE_URL;
export const beckOnlineUrl = import.meta.env.VITE_BECK_ONLINE_URL;
export const beckShopUrl = import.meta.env.VITE_BECK_SHOP_URL;
export const accountUrl = import.meta.env.VITE_ACCOUNT_URL;
export const matomoSiteId = import.meta.env.VITE_MATOMO_SITE_ID;
export const matomoSiteIdPers = import.meta.env.VITE_MATOMO_SITE_ID_PERS;
export const matomoSiteIdStb = import.meta.env.VITE_MATOMO_SITE_ID_STB;
export const matomoSiteIdBr = import.meta.env.VITE_MATOMO_SITE_ID_BR;
export const matomoSiteIdEbib = import.meta.env.VITE_MATOMO_SITE_ID_EBIB;
export const accountProfile = import.meta.env.VITE_ACCOUNT_URL + '/Account/Profile';
export const accountMfaSettings = import.meta.env.VITE_ACCOUNT_URL + '/TwoFactorAuthentication';
export default {
  uiElements,
  store,
  baseUrl,
  beckOnlineUrl,
  beckShopUrl,
  accountUrl,
  accountProfile,
  matomoSiteId,
  matomoSiteIdPers,
  matomoSiteIdStb,
  matomoSiteIdBr,
  matomoSiteIdEbib,
  accountMfaSettings
};
