export function setCookie(cName: string, cValue: string, domain: string): void {
    const expires = "expires=Sitzung";
    domain = domain === "localhost" ? "localhost" : ".beck.de";
    document.cookie = cName + "=" + cValue + ";" + expires + ";path=/" + ";domain=" + domain;
    location.reload();
}
export function eraseCookie(cName: string) {
    const domain = window.location.hostname === "localhost" ? "localhost" : ".beck.de";
    document.cookie = cName + "=" + "; Max-Age=0 " + ";path=/" + "; domain=" + domain;
}
export function getCookie(cName: string) {
    const name: string = cName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}